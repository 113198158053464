/*================================================================================
//  Item Name: RestroX
//  Author: Black Tech
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

$border-red: #da0819;
$border-green: #027a48;
$accent-green: #027a48;
$accent-red: #da0819;

.noCustomScrollBar {
  overflow-x: hidden !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}

.noCustomScrollBar::-webkit-scrollbar {
  display: none;
}
.noCustomScrollBar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: gray;
}

.custom-alert-input {
  background-color: transparent !important;
  input {
    accent-color: #da0819 !important;
    cursor: pointer;
    height: 16px;
    width: 16px;
  }
}

.custom-tab-switch {
  height: 20px !important;
  width: 36px !important;
  cursor: pointer;
}

.custom-tab-switch:checked {
  background-color: #32d583 !important;
  /* border-color: #da0819; */
}

.display-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}

.display-flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.4rem;
}

.text-cursor {
  margin-right: 15px;
  cursor: pointer;
  color: #da0819;
}

.text-cursor:hover {
  color: #e74c3c;
}

.no-border {
  border: none !important;
}

.switch-dinein-menu {
  height: 20px !important;
  width: 36px !important;
  cursor: pointer;
}

.switch-dinein-menu:checked {
  background-color: #32d583 !important;
}

.custom-secondary-btn {
  background-color: #f2f4f7 !important;
  color: #000 !important;
  border: none !important;
}

.custom-secondary-btn:focus {
  background-color: #f2f4f7 !important;
  color: #000 !important;
  border: none !important;
}

.custom-secondary-btn:hover {
  box-shadow: 0 4px 10px -4px #aeb0b3 !important;
}

.display-flex {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.custom-header-text {
  font-weight: 500;
  font-size: 18px;
  margin: 0;
}

.custom-name-text {
  font-weight: 500;
  font-size: 16px;
}

.margin-none {
  margin: 0;
}

.padding-none {
  padding: 0;
}

.margin-top {
  margin-top: 0.4rem;
}

.color-green {
  color: #027a48;
}
.color-red {
  color: #da0819;
}

.border-green {
  border: 1px solid #027a48;
}

.accent-green {
  accent-color: #027a48;
}

.border-red {
  border: 1px solid #da0819;
}

.accent-red {
  accent-color: #da0819;
}

.color-blue {
  color: #1a2b88;
}

.button-red {
  background-color: #feeae8 !important;
  color: #da0819;
  border: none;
  padding: 12px 30px 12px 30px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  width: 100%;
}

.button-red:hover {
  box-shadow: 0 4px 10px -8px #d1cdcd !important;
}

.button-green {
  background-color: #e0f9ec;
  color: #027a48;
  border: none;
  padding: 12px 30px 12px 30px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  width: 100%;
}
.button-green:hover {
  box-shadow: 0 4px 10px -8px #d1cdcd !important;
}

.button-gray {
  background-color: #f2f4f7 !important;
  color: #000 !important;
  border: none;
  padding: 12px 30px 12px 30px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  width: 100%;
}
.button-gray:hover {
  box-shadow: 0 4px 10px -8px #edeff2 !important;
}

.max-container-size {
  max-width: 1200px;
  margin: auto;
}

.sticky-header {
  position: fixed;
  z-index: 10;
  height: 5rem;
}

.input-form {
  label {
    font-weight: 500;
  }
}

.object-fit-cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.cursor-pointer-x {
  cursor: pointer;
  top: 8px;
  right: 8px;
  position: absolute;
}

.add-dish-order-modal {
  min-width: calc(100vw - 580px);
}

@media screen and (max-width: 1800px) {
  .add-dish-order-modal {
    min-width: 900px;
  }
}

@media screen and (max-width: 1200px) {
  .add-dish-order-modal {
    min-width: auto;
  }
}
