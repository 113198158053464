.upload-payment-qr {
  background-color: #feeae8;
  min-width: 240px;
  border: 1px dashed #da0819;
  text-align: center;
  display: grid;
  place-items: center;
  border-radius: 7px;
  cursor: pointer;
  padding: 0.8rem 0rem;
  margin-top: 0.1rem;

  p {
    padding: 0rem 1rem 0rem 1rem;
    font-weight: 400;
    font-size: 11px;
    margin: 0rem 0rem 0rem 0rem;
    padding: 0rem 1rem;
    span {
      color: #da0819;
    }
  }
}

.image-upload-light {
  background-color: #feeae8;
  min-width: 240px;
  border: 1px dashed #da0819;
  text-align: center;
  display: grid;
  place-items: center;
  border-radius: 7px;
  cursor: pointer;
  padding: 0.8rem 0rem;
  margin-top: 0.1rem;

  p {
    padding: 0rem 1rem 0rem 1rem;
    font-weight: 400;
    font-size: 11px;
    margin: 0rem 0rem 0rem 0rem;
    padding: 0rem 1rem;
    span {
      color: #da0819;
    }
  }
}

.image-upload-dark {
  min-width: 240px;
  border: 1px dashed #da0819;
  text-align: center;
  display: grid;
  place-items: center;
  border-radius: 7px;
  cursor: pointer;
  padding: 0.8rem 0rem;
  margin-top: 0.1rem;

  p {
    padding: 0rem 1rem 0rem 1rem;
    font-weight: 400;
    font-size: 11px;
    margin: 0rem 0rem 0rem 0rem;
    padding: 0rem 1rem;
    span {
      color: #da0819;
    }
  }
}

.image-upload-select-light {
  background-color: #ffff;
  border: 1px dashed #b5b2be;
}

.image-upload-select-dark {
  border: 1px dashed #b5b2be;
}

.upload-select-payment-qr {
  border-radius: 7px;
  min-width: 240px;
  display: flex;
  padding: 0.4rem;
  align-items: center;
  img {
    border-radius: 6px;
    width: 88px;
    -o-object-fit: cover;
    object-fit: cover;
    aspect-ratio: 1/1;
  }
  .upload-remove {
    color: #da0819;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
  }
  .upload-replace {
    color: #344054;
    font-weight: 500;
    font-size: 12px;
    cursor: pointer;
  }
  p {
    padding: 0rem 1rem 0rem 1rem;
    margin: 0.4rem 0rem 0rem 0rem;
  }
}

.check-box-btn {
  padding: 8px 12px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  display: flex;
  gap: 0.6rem;
  align-items: center;
  border: 1px solid transparent;
  input {
    cursor: pointer;
    width: 16px;
    height: 16px;
  }
  p {
    margin: 0;
  }
}

.check-btn-green {
  background-color: #e0f9ec;
  color: #027a48;
  border: 1px solid #027a48;
  input {
    accent-color: #027a48;
  }
}

.check-btn-red {
  background-color: #feeae8;
  color: #da0819;
  border: 1px solid #da0819;
  input {
    accent-color: #da0819;
  }
}

.check-btn-default {
  border: 1px solid #ebe9f1;
}
