//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

$restrox-red: #da0819 !default; //$primary

$primary: $restrox-red !default;

$restrox-sidebar-bg: #fffbfb;

$restrox-sidebar-link-white: #feeae8;
$restrox-sidebar-link-dark: #e33d2e;

$restrox-sidebar-text-white: #da0819;
$restrox-sidebar-text-dark: #da0819;

$restrox-border-bottom-light: 1px solid #f0f1f3;
$restrox-border-bottom-dark: 1px solid #303132;

$rextrox-dark-card-bg: #000;

$restrox-app-conteng-bg-light: #fff;
$restrox-app-conteng-bg-dark: #000;
