.error-boundary {
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0rem 2rem;

  p {
    font-size: 1.4rem;
  }
  span {
    color: red;
    cursor: pointer;
    font-size: 1.2rem;
    padding: 0.2rem;
  }
}
